/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import logger from "utils/logger";
import type { ApiRequestOptions } from "./ApiRequestOptions";
import { DOMAIN_COOKIE_KEY, LAUNDRY_COOKIE_KEY, getCookie } from "utils/cookies";

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type OpenAPIConfig = {
  BASE: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  CREDENTIALS: "include" | "omit" | "same-origin";
  TOKEN?: string | Resolver<string> | undefined;
  USERNAME?: string | Resolver<string> | undefined;
  PASSWORD?: string | Resolver<string> | undefined;
  HEADERS?: Headers | Resolver<Headers> | undefined;
  ENCODE_PATH?: ((path: string) => string) | undefined;
  DOMAIN?: string | undefined;
  LAUNDRY?: string | undefined;
};

// Function to retrieve headers from the global context
const getHeadersFromGlobalContext = async (): Promise<Headers> => {
  logger.debug("Getting headers from global context", {
    domain: OpenAPI.DOMAIN,
    laundry: OpenAPI.LAUNDRY,
  });

  if (!OpenAPI.DOMAIN) {
    var domainFromCookie = getCookie(DOMAIN_COOKIE_KEY);
    logger.debug("Domain, not set, getting from cookie", {
      domainFromCookie: domainFromCookie,
    });
    OpenAPI.DOMAIN = domainFromCookie;
  }

  if (!OpenAPI.LAUNDRY) {
    var laundryFromCookie = getCookie(LAUNDRY_COOKIE_KEY);
    logger.debug("Laundry, not set, getting from cookie", {
      laundryFromCookie: laundryFromCookie,
    });
    OpenAPI.LAUNDRY = laundryFromCookie;
  }

  // Replace this with your actual logic to retrieve headers from the global context
  const headers: Headers = {
    Domain: OpenAPI.DOMAIN || "",
  };

  //Add laundry to headers
  if (OpenAPI.LAUNDRY) {
    headers.Laundry = OpenAPI.LAUNDRY;
  }
  return headers;
};

export const OpenAPI: OpenAPIConfig = {
  BASE: process.env.REACT_APP_API_BASE_URL,
  VERSION: "1.0",
  WITH_CREDENTIALS: false,
  CREDENTIALS: "include",
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: getHeadersFromGlobalContext,
  ENCODE_PATH: undefined,
  DOMAIN: undefined,
  LAUNDRY: undefined,
};
