import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomerLayout from "pages/customer/layouts";

const CustomerContracts = () => {
  return (
    <CustomerLayout>
      <Box sx={{ m: 3 }}>
        <Typography variant="h2">Customer Contracts</Typography>
      </Box>
    </CustomerLayout>
  );
};

export default CustomerContracts;
