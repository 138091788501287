import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import React from "react";
import { Root } from "Root";
import { MaterialUIControllerProvider } from "context";
import { BrowserRouter } from "react-router-dom";
import logger from "utils/logger";
import Toast from "components/Toast";
import { initDatadog } from "./config/datadog";
import { QueryProvider } from "providers/QueryProvider";

const root = createRoot(document.getElementById("root"));
const cognitoAuthConfig = {
  authority: process.env.REACT_APP_COGNITO_AUTHORITY,
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
  response_type: "code",
  scope: "email openid phone",
  onSigninCallback: () => {
    logger.info("Signin callback called");
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

// Initialize Datadog before rendering
initDatadog();

root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <QueryProvider>
            <Root />
          </QueryProvider>
          <Toast />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
