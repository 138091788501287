import { createContext } from "react";

export type CustomerContextType = {
  isDestinationVisible: boolean;
  setIsDestinationVisible: (value: boolean) => void;
};

export const CustomerContext = createContext<CustomerContextType>({
  isDestinationVisible: false,
  setIsDestinationVisible: (value: boolean) => {},
});
