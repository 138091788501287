/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerVM } from "../models/CustomerVM";
import type { GarmentTypeVM } from "../models/GarmentTypeVM";
import type { Lmcustomer } from "../models/Lmcustomer";
import type { LmcustomerQueryResponse } from "../models/LmcustomerQueryResponse";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class LmcustomersService {
  /**
   * @param id
   * @returns CustomerVM OK
   * @throws ApiError
   */
  public static getApiLmcustomersVm(id: number): CancelablePromise<CustomerVM> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/lmcustomers/vm/{id}",
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns CustomerVM OK
   * @throws ApiError
   */
  public static putApiLmcustomersVm(
    id: number,
    requestBody?: CustomerVM
  ): CancelablePromise<CustomerVM> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/lmcustomers/vm/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param customerId
   * @param id
   * @returns CustomerVM OK
   * @throws ApiError
   */
  public static deleteApiLmcustomersGarmentTypes(
    customerId: number,
    id: number
  ): CancelablePromise<CustomerVM> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/lmcustomers/{customerId}/garment-types/{id}",
      path: {
        customerId: customerId,
        id: id,
      },
    });
  }
  /**
   * @param customerId
   * @param id
   * @param requestBody
   * @returns CustomerVM OK
   * @throws ApiError
   */
  public static postApiLmcustomersGarmentTypes(
    customerId: number,
    id: number,
    requestBody?: GarmentTypeVM
  ): CancelablePromise<CustomerVM> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/lmcustomers/{customerId}/garment-types/{id}",
      path: {
        customerId: customerId,
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param customerId
   * @param requestBody
   * @returns CustomerVM OK
   * @throws ApiError
   */
  public static postApiLmcustomersGarmentTypes1(
    customerId: number,
    requestBody?: Array<number>
  ): CancelablePromise<CustomerVM> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/lmcustomers/{customerId}/garment-types",
      path: {
        customerId: customerId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param pageNumber
   * @param pageSize
   * @returns LmcustomerQueryResponse OK
   * @throws ApiError
   */
  public static getApiLmcustomers(
    pageNumber: number = 1,
    pageSize: number = 30
  ): CancelablePromise<LmcustomerQueryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/lmcustomers",
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }
  /**
   * @param requestBody
   * @returns Lmcustomer OK
   * @throws ApiError
   */
  public static postApiLmcustomers(requestBody?: Lmcustomer): CancelablePromise<Lmcustomer> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/lmcustomers",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param id
   * @returns Lmcustomer OK
   * @throws ApiError
   */
  public static getApiLmcustomers1(id: number): CancelablePromise<Lmcustomer> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/lmcustomers/{id}",
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static putApiLmcustomers(id: number, requestBody?: Lmcustomer): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/lmcustomers/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteApiLmcustomers(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/lmcustomers/{id}",
      path: {
        id: id,
      },
    });
  }
}
