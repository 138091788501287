/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAccountVM } from "../models/CustomerAccountVM";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class CustomerAccountsService {
  /**
   * @param customerId
   * @param accountId
   * @returns CustomerAccountVM OK
   * @throws ApiError
   */
  public static getApiCustomersAccountsVm(
    customerId: number,
    accountId: number
  ): CancelablePromise<CustomerAccountVM> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/{customerId}/accounts/vm/{accountId}",
      path: {
        customerId: customerId,
        accountId: accountId,
      },
    });
  }
  /**
   * @param customerId
   * @returns CustomerAccountVM OK
   * @throws ApiError
   */
  public static getApiCustomersAccountsVm1(
    customerId: number
  ): CancelablePromise<Array<CustomerAccountVM>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/customers/{customerId}/accounts/vm",
      path: {
        customerId: customerId,
      },
    });
  }
  /**
   * @param customerId
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static postApiCustomersAccountsVm(
    customerId: number,
    requestBody?: CustomerAccountVM
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/customers/{customerId}/accounts/vm",
      path: {
        customerId: customerId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteApiCustomersAccounts(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/customers/accounts/{id}",
      path: {
        id: id,
      },
    });
  }
}
