import { useAuth } from "react-oidc-context";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import UnauthenicatedLayout from "layouts/_unauthenticated";
import { JSX } from "react/jsx-runtime";

function Login(): JSX.Element {
  const auth = useAuth();
  const signOutRedirect = () => {
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const logoutUri = process.env.REACT_APP_COGNITO_REDIRECT_URI;
    const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;

    auth.signoutSilent();

    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}
      &logout_uri=${encodeURIComponent(logoutUri)}`;
  };

  return (
    <UnauthenicatedLayout>
      <MDBox pt={5} px={2}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Information Manager
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => auth.signinRedirect()}
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => signOutRedirect()}
                >
                  sign out
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </UnauthenicatedLayout>
  );
}

export default Login;
