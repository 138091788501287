/* eslint-disable react/prop-types */

import { DefaultCell } from "components/Grid";
import { Link } from "react-router-dom";

const dataTableData = {
  columns: [
    {
      Header: "Billing Address",
      accessor: "billAddr",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Code",
      accessor: "intfAcarCode",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    //Link to the details page
    {
      Header: "Details",
      accessor: "rcId",
      Cell: ({ value }: any) => (
        <Link to={`/customers/${value}`} style={{ color: "blue" }}>
          Edit #{value}
        </Link>
      ),
    },
  ],
  rows: [] as any[],
};

export default dataTableData;
