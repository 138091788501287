import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
export const initDatadog = () => {
  datadogRum.init({
    applicationId: "24490202-263e-4b82-bf0c-e823e4cd21d6",
    clientToken: "pub18ec5569d153cbc2e686bfa4430b7b37",
    site: "us5.datadoghq.com",
    service: "texo-frontend-informationmanager",
    env: process.env.REACT_APP_ENVIRONMENT,
    version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  // Initialize Logs
  datadogLogs.init({
    clientToken: "pub18ec5569d153cbc2e686bfa4430b7b37",
    site: "us5.datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
};
