import { useFormContext } from "react-hook-form";
import { Box, Grid2 as Grid, Card, TextField, Typography } from "@mui/material";
import { CustomerFormData } from "schemas/customerSchema";

const BillingCustomerInfo = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();
  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Billing Information</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Address 1"
                value={watch("billing.address.address1")}
                variant="outlined"
                fullWidth
                {...register("billing.address.address1")}
                error={!!errors.billing?.address?.address1}
                helperText={errors.billing?.address?.address1?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Address 2"
                value={watch("billing.address.address2")}
                variant="outlined"
                fullWidth
                {...register("billing.address.address2")}
                error={!!errors.billing?.address?.address2}
                helperText={errors.billing?.address?.address2?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="City"
                value={watch("billing.address.city")}
                variant="outlined"
                fullWidth
                {...register("billing.address.city")}
                error={!!errors.billing?.address?.city}
                helperText={errors.billing?.address?.city?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="State"
                value={watch("billing.address.state")}
                variant="outlined"
                fullWidth
                {...register("billing.address.state")}
                error={!!errors.billing?.address?.state}
                helperText={errors.billing?.address?.state?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="State"
                value={watch("billing.address.zipcode")}
                variant="outlined"
                fullWidth
                {...register("billing.address.zipcode")}
                error={!!errors.billing?.address?.zipcode}
                helperText={errors.billing?.address?.zipcode?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Phone"
                value={watch("billing.phone")}
                variant="outlined"
                fullWidth
                {...register("billing.phone")}
                error={!!errors.billing?.phone}
                helperText={errors.billing?.phone?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Fax"
                value={watch("billing.fax")}
                variant="outlined"
                fullWidth
                {...register("billing.fax")}
                error={!!errors.billing?.fax}
                helperText={errors.billing?.fax?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Contact"
                value={watch("billing.contact")}
                variant="outlined"
                fullWidth
                {...register("billing.contact")}
                error={!!errors.billing?.contact}
                helperText={errors.billing?.contact?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Email"
                value={watch("billing.email")}
                variant="outlined"
                fullWidth
                {...register("billing.email")}
                error={!!errors.billing?.email}
                helperText={errors.billing?.email?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="BCC"
                value={watch("billing.bcc")}
                variant="outlined"
                fullWidth
                {...register("billing.bcc")}
                error={!!errors.billing?.bcc}
                helperText={errors.billing?.bcc?.message}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default BillingCustomerInfo;
