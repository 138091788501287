import { Box, Typography } from "@mui/material";
import CustomerLayout from "pages/customer/layouts";

const CustomerDestinations = () => {
  return (
    <CustomerLayout>
      <Box sx={{ m: 3 }}>
        <Typography variant="h2">Customer Destinations</Typography>
      </Box>
    </CustomerLayout>
  );
};

export default CustomerDestinations;
