import { useQuery } from "@tanstack/react-query";
import { OpenAPI } from "services/information_manager_api/generated/core/OpenAPI";

type UseDocumentsParams = {
  id: number;
};

const LmdocumentsServiceWrapper = {
  getApiLmdocumentsDownload: async (id: number) => {
    const response = await fetch(`${OpenAPI.BASE}/api/lmdocuments/download/${id}`, {
      method: "GET",
      headers: {
        domain: OpenAPI.DOMAIN,
        Authorization: `Bearer ${OpenAPI.TOKEN}`,
      },
    });
    const blob = await response.blob();
    return blob;
  },
};

export const useDocumentsDownload = (params: UseDocumentsParams) => {
  return useQuery({
    queryKey: ["document", params.id],
    queryFn: () => LmdocumentsServiceWrapper.getApiLmdocumentsDownload(params.id),
  });
};
