import log from "./logger";
import { AuthContextProps } from "react-oidc-context";

export const signOutRedirect = (auth: AuthContextProps) => {
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const logoutUri = process.env.REACT_APP_COGNITO_REDIRECT_URI;
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;

  log.info("User signing out", { clientId, logoutUri });
  auth.signoutSilent();

  const logoutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(
    logoutUri
  )}`;
  log.debug("Redirecting to logout URL", { logoutUrl });
  window.location.href = logoutUrl;
};
