import { Controller, useFormContext } from "react-hook-form";
import { Card, Box, TextField, MenuItem } from "@mui/material";
import { DataTable, DefaultCell } from "components/Grid";
import { CustomerItemsVM } from "services/information_manager_api/generated";
import { CustomerFormData } from "schemas/customerSchema";

const Items = ({ items }: CustomerItemsVM) => {
  const { control } = useFormContext<CustomerFormData>();

  const columns = [
    {
      Header: "Code",
      accessor: "code",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Customer Code",
      accessor: "customerCode",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Customer Description",
      accessor: "customerDescription",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Weight",
      accessor: "weight",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "EERP Quantity",
      accessor: "eerpQuantity",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "EERP Type",
      accessor: "eerpType",
      Cell: ({ value, row }: any) => {
        return (
          <Controller
            name={`items.${row.index}.eerpType`}
            control={control}
            render={({ field }) => (
              <TextField
                select
                variant="outlined"
                fullWidth
                {...field}
                value={field.value?.selectedId || ""}
                onChange={(event) => {
                  field.onChange({
                    ...field.value,
                    selectedId: event.target.value,
                  });
                }}
              >
                {value.items.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        );
      },
    },
    {
      Header: "Commission Employee",
      accessor: "commissionEmployee",
      Cell: ({ value, row }: any) => {
        return (
          <Controller
            name={`items.${row.index}.commissionEmployee`}
            control={control}
            render={({ field }) => (
              <TextField
                select
                variant="outlined"
                fullWidth
                {...field}
                value={field.value?.selectedId || ""}
                onChange={(event) => {
                  field.onChange({
                    ...field.value,
                    selectedId: event.target.value,
                  });
                }}
              >
                {value.items.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        );
      },
    },
    {
      Header: "Commission Account",
      accessor: "commissionAccount",
      Cell: ({ value, row }: any) => {
        const hasItems = value?.items?.length > 0;
        return (
          <>
            {hasItems ? (
              <Controller
                name={`items.${row.index}.commissionAccount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    {...field}
                    value={field.value?.selectedId || ""}
                    onChange={(event) => {
                      field.onChange({
                        ...field.value,
                        selectedId: event.target.value,
                      });
                    }}
                  >
                    {value.items.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ) : (
              <>None</>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Card>
      <Box p={3}>
        <DataTable table={{ columns, rows: items }} entriesPerPage={false} canSearch />
      </Box>
    </Card>
  );
};

export default Items;
