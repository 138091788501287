import { Controller, useFormContext } from "react-hook-form";
import {
  Grid2 as Grid,
  Card,
  Box,
  Typography,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataTable, DefaultCell } from "components/Grid";
import { CustomerGarmentsVM } from "services/information_manager_api/generated/models/CustomerGarmentsVM";
import { CustomerFormData } from "schemas/customerSchema";

const CustomerGarmentsInfo = ({
  replaceWhenDamaged,
  mendingAndRepairs,
  replaceWhenStained,
  garmentTypes,
}: CustomerGarmentsVM) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();

  const columns = [
    {
      Header: "Code",
      accessor: "code",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Maximum Cycles",
      accessor: "maximumCycles",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
  ];

  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Garments Information</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="garments.replaceWhenDamaged.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={replaceWhenDamaged.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={replaceWhenDamaged.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Replace When Damaged"
                        variant="outlined"
                        error={!!errors.garments?.replaceWhenDamaged?.selectedId}
                        helperText={errors.garments?.replaceWhenDamaged?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="garments.mendingAndRepairs.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={mendingAndRepairs.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={mendingAndRepairs.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mending and Repairs"
                        variant="outlined"
                        error={!!errors.garments?.mendingAndRepairs?.selectedId}
                        helperText={errors.garments?.mendingAndRepairs?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="garments.replaceWhenStained.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={replaceWhenStained.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={replaceWhenStained.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Replace When Stained"
                        variant="outlined"
                        error={!!errors.garments?.replaceWhenStained?.selectedId}
                        helperText={errors.garments?.replaceWhenStained?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("garments.limitUnassignedGarments")}
                    {...register("garments.limitUnassignedGarments")}
                    color="primary"
                  />
                }
                label="Limit Unassigned Garments"
              />
            </Grid>
          </Grid>
          <DataTable table={{ columns, rows: garmentTypes }} entriesPerPage={false} canSearch />
        </Box>
      </Box>
    </Card>
  );
};

export default CustomerGarmentsInfo;
