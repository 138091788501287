import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { CircularProgress, Box, Card, Typography, Button, Stack, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import CustomerLayout from "pages/customer/layouts";
import DeleteDialog from "pages/customer/accounts/components/DeleteDialog";
import { DataTable } from "components/Grid";
import { DefaultCell } from "components/Grid";
import { useCustumerAccounts } from "hooks/useCustomers";
import { useMaterialUIController, setToast } from "context";

const AccountTypeENUM: {
  1: string;
  2: string;
  3: string;
} = {
  1: "Cost",
  2: "Census",
  3: "Inventory",
};

const CustomerAccounts = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isError, isFetched, refetch } = useCustumerAccounts({ id: Number(id) });
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);

  useEffect(() => {
    refetch();
  }, [id]);

  if (isError) {
    setToast(dispatch, {
      open: true,
      message: "Failed to fetch customer accounts",
      snackbarProps: {
        autoHideDuration: 3500,
      },
    });
  }

  const handleSelected = (id: number) => {
    setSelectedAccount(id);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    refetch();
  };

  const columns = [
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }: { value: number }) => (
        <DefaultCell value={AccountTypeENUM[value as 1 | 2 | 3]} />
      ),
    },
    {
      Header: "Code",
      accessor: "code",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Details",
      accessor: "id",
      Cell: ({ value }: any) => (
        <>
          <Link to={`/customers/${id}/accounts/${value}`}>
            <IconButton aria-label="edit">
              <Edit />
            </IconButton>
          </Link>
          <IconButton aria-label="delete" onClick={() => handleSelected(value)}>
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <CustomerLayout>
      <Box pt={2} px={2}>
        <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6">Customer Accounts for #{id}</Typography>
          <Link to={`/customers/${id}/accounts/add`}>
            <Button variant="contained">Add new account</Button>
          </Link>
        </Stack>
      </Box>
      <Box p={2}>
        <Card>
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {isFetched && <DataTable table={{ columns, rows: data }} entriesPerPage={false} />}
        </Card>
      </Box>
      <DeleteDialog open={open} onClose={handleCloseModal} accountId={selectedAccount} />
    </CustomerLayout>
  );
};

export default CustomerAccounts;
