import { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid2 as Grid, Card, Box, Autocomplete, TextField, Typography } from "@mui/material";

import { CustomerContext } from "context/customer";
import { CustomerFormData } from "schemas/customerSchema";
import { CustomerGeneralVM } from "services/information_manager_api/generated/models/CustomerGeneralVM";

const GeneralCustomerInfo = ({
  censusAccount,
  commissionAccount,
  commissionEmployee,
  documentationProfile,
  notificationProfile,
  naics,
  billingAddressProfile,
  destinationProfile,
  orderProfile,
  soilProfile,
  statementProfile,
  customerServiceRep,
  parentCustomer,
  contractProfile,
}: CustomerGeneralVM) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();
  const { setIsDestinationVisible } = useContext(CustomerContext);
  const destinationProfileId = watch("general.destinationProfile.selectedId");

  useEffect(() => {
    if (destinationProfileId !== "1") {
      setIsDestinationVisible(true);
    } else {
      setIsDestinationVisible(false);
    }
  }, [destinationProfileId]);

  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Customer Information</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Name*"
                value={watch("general.name")}
                variant="outlined"
                fullWidth
                {...register("general.name")}
                error={!!errors.general?.name}
                helperText={errors.general?.name?.message || ""}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Code*"
                value={watch("general.code")}
                variant="outlined"
                fullWidth
                {...register("general.code")}
                error={!!errors.general?.code}
                helperText={errors.general?.code?.message || ""}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.censusAccount.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={censusAccount.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={censusAccount.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Census Account"
                        variant="outlined"
                        error={!!errors.general?.censusAccount?.selectedId}
                        helperText={errors.general?.censusAccount?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.commissionAccount.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={commissionAccount.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={commissionAccount.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Commission Account"
                        variant="outlined"
                        error={!!errors.general?.commissionAccount?.selectedId}
                        helperText={errors.general?.commissionAccount?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.commissionEmployee.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={commissionEmployee.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={commissionEmployee.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Commission Employee"
                        variant="outlined"
                        error={!!errors.general?.commissionEmployee?.selectedId}
                        helperText={errors.general?.commissionEmployee?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.documentationProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={documentationProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      documentationProfile.items.find((item) => item.id === field.value) || null
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Documentation Profile"
                        variant="outlined"
                        error={!!errors.general?.documentationProfile?.selectedId}
                        helperText={errors.general?.documentationProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.notificationProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={notificationProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      notificationProfile.items.find((item) => item.id === field.value) || null
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Notification Profile"
                        variant="outlined"
                        error={!!errors.general?.notificationProfile?.selectedId}
                        helperText={errors.general?.notificationProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.naics.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={naics.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={naics.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="NAICS"
                        variant="outlined"
                        error={!!errors.general?.naics?.selectedId}
                        helperText={errors.general?.naics?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.billingAddressProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={billingAddressProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      billingAddressProfile.items.find((item) => item.id === field.value) || null
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Billing Address Profile"
                        variant="outlined"
                        error={!!errors.general?.billingAddressProfile?.selectedId}
                        helperText={
                          errors.general?.billingAddressProfile?.selectedId?.message || ""
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.destinationProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={destinationProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={destinationProfile.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination Profile"
                        variant="outlined"
                        error={!!errors.general?.destinationProfile?.selectedId}
                        helperText={errors.general?.destinationProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.orderProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={orderProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={orderProfile.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Order Profile"
                        variant="outlined"
                        error={!!errors.general?.orderProfile?.selectedId}
                        helperText={errors.general?.orderProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.soilProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={soilProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={soilProfile.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Soil Profile"
                        variant="outlined"
                        error={!!errors.general?.soilProfile?.selectedId}
                        helperText={errors.general?.soilProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.statementProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={statementProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={statementProfile.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Statement Profile"
                        variant="outlined"
                        error={!!errors.general?.statementProfile?.selectedId}
                        helperText={errors.general?.statementProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.customerServiceRep.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={customerServiceRep.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={customerServiceRep.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Service Rep"
                        variant="outlined"
                        error={!!errors.general?.customerServiceRep?.selectedId}
                        helperText={errors.general?.customerServiceRep?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.parentCustomer.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={parentCustomer.billCustomers}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      parentCustomer.billCustomers.find((item) => item.id === field.value) || null
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parent Billing/Contract Customer"
                        variant="outlined"
                        error={!!errors.general?.parentCustomer?.selectedId}
                        helperText={errors.general?.parentCustomer?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="general.contractProfile.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={contractProfile.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={contractProfile.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contract Profile"
                        variant="outlined"
                        error={!!errors.general?.contractProfile?.selectedId}
                        helperText={errors.general?.contractProfile?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default GeneralCustomerInfo;
