import { createContext, ReactNode, useContext, useMemo, useReducer } from "react";
import { AlertProps } from "@mui/material/Alert";
import { SnackbarProps } from "@mui/material/Snackbar";

// The Material Dashboard 2 PRO React TSUI Dashboard PRO Material main context
const MaterialUI = createContext<any>(null);

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

interface LoggedInProfileType {
  email: string;
  domain: string;
  laundry: string;
}

interface ToastType {
  open: boolean;
  message: string;
  alertProps?: AlertProps;
  snackbarProps?: SnackbarProps;
}

// types
interface StateTypes {
  isSidebarOpen: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  layout: "dashboard" | "page";
  loggedInProfile: LoggedInProfileType | null;
  domainLaundryModal: boolean;
  toast: ToastType;
}

interface ActionTypes {
  type:
    | "IS_SIDEBAR_OPEN"
    | "TRANSPARENT_SIDENAV"
    | "WHITE_SIDENAV"
    | "SIDENAV_COLOR"
    | "TRANSPARENT_NAVBAR"
    | "FIXED_NAVBAR"
    | "OPEN_CONFIGURATOR"
    | "LAYOUT"
    | "PROFILE"
    | "DOMAIN_LAUNDRY_MODAL"
    | "TOAST";
  value: any;
}

// Material Dashboard 2 PRO React reducer
function reducer(state: StateTypes, action: ActionTypes) {
  switch (action.type) {
    case "IS_SIDEBAR_OPEN": {
      return { ...state, isSidebarOpen: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "PROFILE": {
      return { ...state, loggedInProfile: action.value };
    }
    case "DOMAIN_LAUNDRY_MODAL": {
      return { ...state, domainLaundryModal: action.value };
    }
    case "TOAST": {
      return { ...state, toast: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }: { children: ReactNode }): JSX.Element {
  const initialState: StateTypes = {
    isSidebarOpen: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    layout: "dashboard",
    loggedInProfile: null,
    domainLaundryModal: false,
    toast: {
      open: false,
      message: "Simple message",
    },
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Context module functions
const setIsSidebarOpen = (
  dispatch: (arg: { type: "IS_SIDEBAR_OPEN"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "IS_SIDEBAR_OPEN", value });
const setTransparentSidenav = (
  dispatch: (arg: { type: "TRANSPARENT_SIDENAV"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (
  dispatch: (arg: { type: "WHITE_SIDENAV"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (
  dispatch: (arg: {
    type: "SIDENAV_COLOR";
    value: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  }) => void,
  value: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark"
) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (
  dispatch: (arg: { type: "TRANSPARENT_NAVBAR"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (
  dispatch: (arg: { type: "FIXED_NAVBAR"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "FIXED_NAVBAR", value });
const setLayout = (
  dispatch: (arg: { type: "LAYOUT"; value: "dashboard" | "page" }) => void,
  value: "dashboard" | "page"
) => dispatch({ type: "LAYOUT", value });
const setLoggedInProfile = (
  dispatch: (arg: { type: "PROFILE"; value: LoggedInProfileType | null }) => void,
  value: LoggedInProfileType | null
) => {
  dispatch({ type: "PROFILE", value });
};
const setDomainLaundryModal = (
  dispatch: (arg: { type: "DOMAIN_LAUNDRY_MODAL"; value: boolean | null }) => void,
  value: boolean | null
) => {
  dispatch({ type: "DOMAIN_LAUNDRY_MODAL", value });
};
const setToast = (
  dispatch: (arg: { type: "TOAST"; value: ToastType | null }) => void,
  value: ToastType | null
) => {
  dispatch({ type: "TOAST", value });
};

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setIsSidebarOpen,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setLayout,
  setLoggedInProfile,
  setDomainLaundryModal,
  setToast,
};
