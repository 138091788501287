import { Controller, useFormContext } from "react-hook-form";
import {
  Grid2 as Grid,
  Card,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import { CustomerRoutingVM } from "services/information_manager_api/generated/models/CustomerRoutingVM";
import { CustomerFormData } from "schemas/customerSchema";

const RoutingInfo = ({ stopPriority, preferredWindowPriority }: CustomerRoutingVM) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();

  // Helper function to parse time string to Date object
  const parseTimeString = (timeString: string | null | undefined): Date | null => {
    if (!timeString) return null;

    // If it's already a date string with date part, just parse it
    if (timeString.includes("T") || timeString.includes("-")) {
      return new Date(timeString);
    }

    // If it's just a time string (HH:MM or HH:MM:SS), add today's date
    const today = new Date();
    const [hours, minutes, seconds = "00"] = (timeString || "").split(":");

    if (!hours || !minutes) return null;

    today.setHours(parseInt(hours, 10));
    today.setMinutes(parseInt(minutes, 10));
    today.setSeconds(parseInt(seconds, 10));

    return today;
  };

  // Helper function to format time for the backend
  const formatTimeForBackend = (date: Date | string): string => {
    if (!date) return "";

    const d = typeof date === "string" ? new Date(date) : date;
    return `${d.getHours().toString().padStart(2, "0")}:${d
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Routing Information</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isSundayAvailable")}
                    {...register("routing.isSundayAvailable")}
                    color="primary"
                  />
                }
                label="Sunday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isMondayAvailable")}
                    {...register("routing.isMondayAvailable")}
                    color="primary"
                  />
                }
                label="Monday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isTuesdayAvailable")}
                    {...register("routing.isTuesdayAvailable")}
                    color="primary"
                  />
                }
                label="Tuesday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isWednesdayAvailable")}
                    {...register("routing.isWednesdayAvailable")}
                    color="primary"
                  />
                }
                label="Wednesday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isThursdayAvailable")}
                    {...register("routing.isThursdayAvailable")}
                    color="primary"
                  />
                }
                label="Thursday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isFridayAvailable")}
                    {...register("routing.isFridayAvailable")}
                    color="primary"
                  />
                }
                label="Friday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("routing.isSaturdayAvailable")}
                    {...register("routing.isSaturdayAvailable")}
                    color="primary"
                  />
                }
                label="Saturday Available"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                Available Delivery Time Start
              </Typography>
              <Controller
                name="routing.availableDeliveryTimeStart"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      minuteIncrement: 5,
                      allowInput: true,
                    }}
                    onChange={(dates: Date[], dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    input={{ sx: { width: "100%" } }}
                    error={!!errors.routing?.availableDeliveryTimeStart}
                    helperText={errors.routing?.availableDeliveryTimeStart?.message || ""}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                Available Delivery Time End
              </Typography>
              <Controller
                name="routing.availableDeliveryTimeEnd"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      minuteIncrement: 5,
                      allowInput: true,
                    }}
                    onChange={(dates: Date[], dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    input={{ sx: { width: "100%" } }}
                    error={!!errors.routing?.availableDeliveryTimeEnd}
                    helperText={errors.routing?.availableDeliveryTimeEnd?.message || ""}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                First Preferred Delivery Window Time Start
              </Typography>
              <Controller
                name="routing.firstPreferredDeliveryWindowTimeStart"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      minuteIncrement: 5,
                      allowInput: true,
                    }}
                    onChange={(dates: Date[], dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    input={{ sx: { width: "100%" } }}
                    error={!!errors.routing?.firstPreferredDeliveryWindowTimeStart}
                    helperText={
                      errors.routing?.firstPreferredDeliveryWindowTimeStart?.message || ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                First Preferred Delivery Window Time End
              </Typography>
              <Controller
                name="routing.firstPreferredDeliveryWindowTimeEnd"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      minuteIncrement: 5,
                      allowInput: true,
                    }}
                    onChange={(dates: Date[], dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    input={{ sx: { width: "100%" } }}
                    error={!!errors.routing?.firstPreferredDeliveryWindowTimeEnd}
                    helperText={errors.routing?.firstPreferredDeliveryWindowTimeEnd?.message || ""}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                Second Preferred Delivery Window Time Start
              </Typography>
              <Controller
                name="routing.secondPreferredDeliveryWindowTimeStart"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      minuteIncrement: 5,
                      allowInput: true,
                    }}
                    onChange={(dates: Date[], dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    input={{ sx: { width: "100%" } }}
                    error={!!errors.routing?.secondPreferredDeliveryWindowTimeStart}
                    helperText={
                      errors.routing?.secondPreferredDeliveryWindowTimeStart?.message || ""
                    }
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                Second Preferred Delivery Window Time End
              </Typography>
              <Controller
                name="routing.secondPreferredDeliveryWindowTimeEnd"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      minuteIncrement: 5,
                      allowInput: true,
                    }}
                    onChange={(dates: Date[], dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    input={{ sx: { width: "100%" } }}
                    error={!!errors.routing?.secondPreferredDeliveryWindowTimeEnd}
                    helperText={errors.routing?.secondPreferredDeliveryWindowTimeEnd?.message || ""}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Average Delivery Duration"
                value={watch("routing.averageDeliveryDuration")}
                variant="outlined"
                fullWidth
                {...register("routing.averageDeliveryDuration")}
                error={!!errors.routing?.averageDeliveryDuration}
                helperText={errors.routing?.averageDeliveryDuration?.message || ""}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="routing.stopPriority.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={stopPriority.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={stopPriority.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Census Account"
                        variant="outlined"
                        error={!!errors.routing?.stopPriority?.selectedId}
                        helperText={errors.routing?.stopPriority?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="routing.preferredWindowPriority.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={preferredWindowPriority.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={
                      preferredWindowPriority.items.find((item) => item.id === field.value) || null
                    }
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Census Account"
                        variant="outlined"
                        error={!!errors.routing?.preferredWindowPriority?.selectedId}
                        helperText={
                          errors.routing?.preferredWindowPriority?.selectedId?.message || ""
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default RoutingInfo;
