import { DBV_Note_Type } from "services/information_manager_api/generated";

export const formatAuditTrailLabel = (dateString: string, userRcId: number): string => {
  const formattedDateString = new Date(dateString).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });

  const formattedUserRcId = userRcId >= 0 ? (userRcId % 10000).toString() : "N/A";

  return `${formattedDateString} by User ID: ${formattedUserRcId}`;
};

export const formatNoteTypeLabel = (noteType: DBV_Note_Type): string => {
  switch (noteType) {
    case DBV_Note_Type._1:
      return "Setup Change";
    case DBV_Note_Type._2:
      return "Change Audit";
    case DBV_Note_Type._3:
      return "Customer Service";
    case DBV_Note_Type._4:
      return "Accounts Receivable";
    case DBV_Note_Type._5:
      return "Change Request Order";
    case DBV_Note_Type._6:
      return "Change Request Wearer";
    default:
      return "";
  }
};
