import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Breadcrumb from "components/Breadcrumb";
import dataTableData from "./data/dataTableData";
import { DataTable } from "components/Grid";
import { useMaterialUIController, setToast } from "context";
import { useCustomers } from "hooks/useCustomers";

const CustomerList = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { data, isLoading, isError } = useCustomers();

  if (isError) {
    setToast(dispatch, {
      open: true,
      message: "Failed to fetch customers",
      snackbarProps: {
        autoHideDuration: 3500,
      },
    });
  }

  return (
    <Box>
      <Breadcrumb />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 130px)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ m: 4 }}>
          <Typography variant="h4" sx={{ m: 1 }}>
            Customers
          </Typography>
          <Card>
            <DataTable
              table={{ ...dataTableData, rows: data.items }}
              entriesPerPage={false}
              canSearch
            />
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default CustomerList;
