import typography from "assets/theme/base/typography";

type Types = any;

const listItemText: Types = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
    primary: {
      ...typography.menu,
    },
  },
};

export default listItemText;
