import { Controller, useFormContext } from "react-hook-form";
import {
  Grid2 as Grid,
  Card,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import { CustomerAccountingVM } from "services/information_manager_api/generated";
import { CustomerFormData } from "schemas/customerSchema";

const CustomerAccountingInfo = ({ ediVanOptions }: CustomerAccountingVM) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();
  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Accounting Information</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="DUNS Number"
                value={watch("accounting.dunsNumber")}
                variant="outlined"
                fullWidth
                {...register("accounting.dunsNumber")}
                error={!!errors.accounting?.dunsNumber}
                helperText={errors.accounting?.dunsNumber?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Accounts Receivable Code"
                value={watch("accounting.accountsReceivableCode")}
                variant="outlined"
                fullWidth
                {...register("accounting.accountsReceivableCode")}
                error={!!errors.accounting?.accountsReceivableCode}
                helperText={errors.accounting?.accountsReceivableCode?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Override Sales Account Code"
                value={watch("accounting.overrideSalesAccountCode")}
                variant="outlined"
                fullWidth
                {...register("accounting.overrideSalesAccountCode")}
                error={!!errors.accounting?.overrideSalesAccountCode}
                helperText={errors.accounting?.overrideSalesAccountCode?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Blanket Purchase Order"
                value={watch("accounting.blanketPurchaseOrder")}
                variant="outlined"
                fullWidth
                {...register("accounting.blanketPurchaseOrder")}
                error={!!errors.accounting?.blanketPurchaseOrder}
                helperText={errors.accounting?.blanketPurchaseOrder?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Credit Limit"
                type="number"
                value={watch("accounting.creditLimit")}
                variant="outlined"
                fullWidth
                {...register("accounting.creditLimit", {
                  valueAsNumber: true,
                })}
                error={!!errors.accounting?.creditLimit}
                helperText={errors.accounting?.creditLimit?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Invoice Reference"
                value={watch("accounting.invoiceReference")}
                variant="outlined"
                fullWidth
                {...register("accounting.invoiceReference")}
                error={!!errors.accounting?.invoiceReference}
                helperText={errors.accounting?.invoiceReference?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="accounting.ediVanOptions.selectedId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={ediVanOptions.items}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={ediVanOptions.items.find((item) => item.id === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ediVan"
                        variant="outlined"
                        error={!!errors.accounting?.ediVanOptions?.selectedId}
                        helperText={errors.accounting?.ediVanOptions?.selectedId?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("accounting.overrideInvoiceMinimum")}
                    {...register("accounting.overrideInvoiceMinimum")}
                    color="primary"
                  />
                }
                label="Override Invoice Minimum"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                Activation Date
              </Typography>
              <Controller
                name="accounting.activationDate"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    onChange={(date: Date | null, dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    error={!!errors.accounting?.activationDate}
                    helperText={errors.accounting?.activationDate?.message || ""}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography mr={2} variant="caption" fontWeight="regular" color="text">
                Deactivation Date
              </Typography>
              <Controller
                name="accounting.deactivationDate"
                control={control}
                render={({ field }) => (
                  <MDDatePicker
                    value={field.value}
                    onChange={(date: Date | null, dateStr: string) => {
                      field.onChange(dateStr);
                    }}
                    error={!!errors.accounting?.deactivationDate}
                    helperText={errors.accounting?.deactivationDate?.message || ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default CustomerAccountingInfo;
