import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

const { info, white, gradients } = colors;

// Improved type definition
type FlatpickrStyles = Record<string, Record<string, string | number | Record<string, string>>>;

// Common styles for reuse
const commonBorder = { border: "none" };

const flatpickr: FlatpickrStyles = {
  ".flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus":
    {
      background: rgba(info.main, 0.28),
      ...commonBorder,
    },

  ".flatpickr-day.today": {
    background: info.main,
    color: white.main,
    ...commonBorder,

    "&:hover, &:focus": {
      background: `${info.focus}`,
    },
  },

  ".flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus":
    {
      background: `${gradients.info.state}`,
      color: white.main,
      ...commonBorder,
    },

  ".flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg":
    {
      color: `${info.main}`,
      fill: `${info.main}`,
    },

  // Time picker styles
  ".flatpickr-calendar.open": {
    width: "auto",
    minWidth: "120px",
    maxWidth: "none",
  },

  ".flatpickr-calendar.open .flatpickr-time": {
    alignItems: "center",
    justifyContent: "center",
  },

  ".flatpickr-calendar.open .numInputWrapper": {
    flex: "0 0 40px",
  },
};

export default flatpickr;
