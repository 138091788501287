// ==================================
// Code to handle authentication
// ==================================
import { useAuth } from "react-oidc-context";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import App from "App";
import log from "./utils/logger";
import { signOutRedirect } from "./utils/signout";

export function Root() {
  const auth = useAuth();

  if (auth.isLoading) {
    log.debug("Auth loading...");
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (auth.error) {
    log.error("Auth error", { error: auth.error });
    return (
      <div>
        Encountering error... {auth.error.message}
        <br />
        <br />
        <button onClick={() => signOutRedirect(auth)}>Sign out</button>
      </div>
    );
  }

  log.debug("Auth successful, rendering App");
  return <App />;
}

export default Root;
