import { useFormContext } from "react-hook-form";
import { Grid2 as Grid, Card, Box, Typography, TextField } from "@mui/material";
import { CustomerFormData } from "schemas/customerSchema";

const ShippingInfo = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();
  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Shipping Information</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Address 1"
                value={watch("shipping.address.address1")}
                variant="outlined"
                fullWidth
                {...register("shipping.address.address1")}
                error={!!errors.shipping?.address?.address1}
                helperText={errors.shipping?.address?.address1?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Address 2"
                value={watch("shipping.address.address2")}
                variant="outlined"
                fullWidth
                {...register("shipping.address.address2")}
                error={!!errors.shipping?.address?.address2}
                helperText={errors.shipping?.address?.address2?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="City"
                value={watch("shipping.address.city")}
                variant="outlined"
                fullWidth
                {...register("shipping.address.city")}
                error={!!errors.shipping?.address?.city}
                helperText={errors.shipping?.address?.city?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="State"
                value={watch("shipping.address.state")}
                variant="outlined"
                fullWidth
                {...register("shipping.address.state")}
                error={!!errors.shipping?.address?.state}
                helperText={errors.shipping?.address?.state?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Zipcode"
                value={watch("shipping.address.zipcode")}
                variant="outlined"
                fullWidth
                {...register("shipping.address.zipcode")}
                error={!!errors.shipping?.address?.zipcode}
                helperText={errors.shipping?.address?.zipcode?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Phone"
                value={watch("shipping.phone")}
                variant="outlined"
                fullWidth
                {...register("shipping.phone")}
                error={!!errors.shipping?.phone}
                helperText={errors.shipping?.phone?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Fax"
                value={watch("shipping.fax")}
                variant="outlined"
                fullWidth
                {...register("shipping.fax")}
                error={!!errors.shipping?.fax}
                helperText={errors.shipping?.fax?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Contact"
                value={watch("shipping.contact")}
                variant="outlined"
                fullWidth
                {...register("shipping.contact")}
                error={!!errors.shipping?.contact}
                helperText={errors.shipping?.contact?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Email"
                value={watch("shipping.email")}
                variant="outlined"
                fullWidth
                {...register("shipping.email")}
                error={!!errors.shipping?.email}
                helperText={errors.shipping?.email?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="BCC"
                value={watch("shipping.bcc")}
                variant="outlined"
                fullWidth
                {...register("shipping.bcc")}
                error={!!errors.shipping?.bcc}
                helperText={errors.shipping?.bcc?.message}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="FIPS Code"
                value={watch("shipping.fipsCode")}
                variant="outlined"
                fullWidth
                {...register("shipping.fipsCode")}
                error={!!errors.shipping?.fipsCode}
                helperText={errors.shipping?.fipsCode?.message}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default ShippingInfo;
