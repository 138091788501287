import { Card, Box, Typography } from "@mui/material";
import { DataTable, DefaultCell } from "components/Grid";
import { CustomerDocumentsVM } from "services/information_manager_api/generated/models/CustomerDocumentsVM";
import DocumentDownloadButton from "components/DocumentDownloadButton";

function CustomerDocumentation({ documents }: CustomerDocumentsVM): JSX.Element {
  const columns = [
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Document File",
      accessor: "fileName",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Document Date",
      accessor: "createdAt",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Added Date",
      accessor: "modifiedAt",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ value, row }: any) => (
        <DocumentDownloadButton id={value} fileName={row.original.fileName} />
      ),
    },
  ];

  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Documentation</Typography>
        <DataTable table={{ columns, rows: documents }} entriesPerPage={false} canSearch />
      </Box>
    </Card>
  );
}

export default CustomerDocumentation;
