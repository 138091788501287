import { useEffect, useState } from "react";
import { Card, Box, Typography, FormControlLabel, Checkbox, Grid2 } from "@mui/material";
import { DataTable } from "components/Grid";
import dataTableData from "./data/dataTableData";
import { formatAuditTrailLabel, formatNoteTypeLabel } from "./helpers/formatters";
import { DBV_Note_Type } from "services/information_manager_api/generated";
import { CustomerNotesVM } from "services/information_manager_api/generated/models/CustomerNotesVM";

const CustomerNotes = ({ notes }: CustomerNotesVM) => {
  const formattedNotes = notes.map((note) => ({
    ...note,
    noteTypeLabel: formatNoteTypeLabel(note.noteType),
    auditTrailLabel: formatAuditTrailLabel(note.createdAt, note.userRcId),
    highlight: note.noteType === DBV_Note_Type._2,
  }));

  const [tableData, setTableData] = useState<any>({
    ...dataTableData,
    rows: formattedNotes,
  });

  const [showAuditOnly, setShowAuditOnly] = useState<boolean>(false);
  // create state for search input in order to search based on Audit filter
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    const filteredNotes = filterNotes(formattedNotes, showAuditOnly, searchInput);
    setTableData({ ...tableData, rows: filteredNotes });
  }, [showAuditOnly, searchInput]);

  const filterNotes = (notes: any[], showAuditOnly: boolean, searchInput: string) => {
    const lowercasedInput = searchInput.toLowerCase();

    return notes.filter((note: any) => {
      // help from Copilot
      const matchesAuditFilter = !showAuditOnly || note.noteType === DBV_Note_Type._2;
      const matchesSearchFilter =
        !searchInput ||
        note.text.toLowerCase().includes(lowercasedInput) ||
        note.createdAt.toLowerCase().includes(lowercasedInput) ||
        note.subject.toLowerCase().includes(lowercasedInput) ||
        note.noteTypeLabel.toLowerCase().includes(lowercasedInput);

      return matchesAuditFilter && matchesSearchFilter;
    });
  };

  const handleAuditCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAuditOnly(event.target.checked);
  };

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Notes</Typography>
        <Grid2 container justifyContent={"flex-end"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAuditOnly}
                onChange={handleAuditCheckboxChange}
                name="showAuditOnly"
                color="primary"
              />
            }
            label="Show Audit Notes Only"
          />
        </Grid2>
        <DataTable
          table={tableData}
          entriesPerPage={false}
          canSearch
          // pass handleSearchChange so that we can pass it to the search element to help handle our tableData state
          onSearchChange={handleSearchChange}
          isMultiSorted={true}
        />
      </Box>
    </Card>
  );
};

export default CustomerNotes;
