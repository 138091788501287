/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Lmlaundry } from "../models/Lmlaundry";
import type { LmlaundryQueryResponse } from "../models/LmlaundryQueryResponse";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
export class LmlaundriesService {
  /**
   * @returns Lmlaundry OK
   * @throws ApiError
   */
  public static getApiLmlaundriesByUser(): CancelablePromise<Array<Lmlaundry>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Lmlaundries/by-user",
    });
  }
  /**
   * @param pageNumber
   * @param pageSize
   * @returns LmlaundryQueryResponse OK
   * @throws ApiError
   */
  public static getApiLmlaundries(
    pageNumber: number = 1,
    pageSize: number = 30
  ): CancelablePromise<LmlaundryQueryResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Lmlaundries",
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }
  /**
   * @param requestBody
   * @returns Lmlaundry OK
   * @throws ApiError
   */
  public static postApiLmlaundries(requestBody?: Lmlaundry): CancelablePromise<Lmlaundry> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Lmlaundries",
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param id
   * @returns Lmlaundry OK
   * @throws ApiError
   */
  public static getApiLmlaundries1(id: number): CancelablePromise<Lmlaundry> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Lmlaundries/{id}",
      path: {
        id: id,
      },
    });
  }
  /**
   * @param id
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static putApiLmlaundries(id: number, requestBody?: Lmlaundry): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/Lmlaundries/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteApiLmlaundries(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/Lmlaundries/{id}",
      path: {
        id: id,
      },
    });
  }
}
