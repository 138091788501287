import { useQuery } from "@tanstack/react-query";
import { LmcustomersService } from "services/information_manager_api/generated/services/LmcustomersService";
import { CustomerAccountsService } from "services/information_manager_api/generated/services/CustomerAccountsService";

export const useCustomers = () => {
  return useQuery({
    queryKey: ["customers"],
    queryFn: () => LmcustomersService.getApiLmcustomers(),
  });
};

type UseCustumerParams = {
  id: number;
};

export const useCustomer = (params: UseCustumerParams) => {
  return useQuery({
    queryKey: ["customer", params],
    queryFn: () => LmcustomersService.getApiLmcustomersVm(params.id),
  });
};

export const useCustumerAccounts = (params: UseCustumerParams) => {
  return useQuery({
    queryKey: ["customerAccounts", params],
    queryFn: () => CustomerAccountsService.getApiCustomersAccountsVm1(params.id),
  });
};

type UseCustumerAccountParams = {
  customerId: number;
  accountId: number;
};

export const useCustumerAccount = (params: UseCustumerAccountParams) => {
  return useQuery({
    queryKey: ["getCustomerAccount", params],
    queryFn: () =>
      CustomerAccountsService.getApiCustomersAccountsVm(params.customerId, params.accountId),
  });
};
