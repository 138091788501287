import { ReactNode, useState } from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";

import Breadcrumb from "components/Breadcrumb";
import List from "pages/customer/details/components/List";
import { CustomerContext } from "context/customer";

function CustomerLayout({ children }: { children: ReactNode }): JSX.Element {
  const [isDestinationVisible, setIsDestinationVisible] = useState(false);

  return (
    <CustomerContext.Provider value={{ isDestinationVisible, setIsDestinationVisible }}>
      <Breadcrumb />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 12, lg: 2 }}>
            <Box mx={1} my={2}>
              <List />
            </Box>
          </Grid>
          <Grid size="grow">{children}</Grid>
        </Grid>
      </Box>
    </CustomerContext.Provider>
  );
}

export default CustomerLayout;
