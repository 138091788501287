import { SyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { Alert, AlertTitle, CircularProgress, Tabs, Tab, Box, Button } from "@mui/material";

import CustomerGarmentsInfo from "pages/customer/details/components/Garments";
import CustomerLayout from "pages/customer/layouts";
import Header from "pages/customer/details/components/Header";
import GeneralCustomerInfo from "pages/customer/details/components/General";
import BillingCustomerInfo from "pages/customer/details/components/Billing";
import ShippingInfo from "pages/customer/details/components/Shipping";
import CustomerAccountingInfo from "pages/customer/details/components/Accounting";
import Items from "pages/customer/details/components/Items";
import { CustomerVM } from "services/information_manager_api/generated";

import { useMaterialUIController, setToast } from "context";
import { useCustomer } from "hooks/useCustomers";
import { useUpdateCustomer } from "hooks/useUpdateCustomer";
import { customerSchema, CustomerFormData } from "schemas/customerSchema";

import {
  StepsENUM,
  StepsInitialState,
  StepsWithBillingState,
} from "pages/customer/details/helpers";
import CustomerNotes from "./components/Notes";
import RoutingInfo from "./components/Routing";
import CustomerDocumentation from "./components/Documentation";
import CustomerOverrideRemittance from "./components/OverrideRemittance";

function CustomerDetails(): JSX.Element {
  const [context, dispatch] = useMaterialUIController();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isError, isFetched } = useCustomer({ id: Number(id) });
  const { mutate: updateCustomer, isPending } = useUpdateCustomer();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState(StepsInitialState);
  const [errorBEMessages, setErrorBEMessages] = useState<string[]>([]);

  if (isError) {
    setToast(dispatch, {
      open: true,
      message: "Failed to fetch customer",
      snackbarProps: {
        autoHideDuration: 3500,
      },
    });
  }

  const mapGeneralFields = (
    fields: Record<string, { selectedId?: string } | undefined>
  ): Record<string, { selectedId: string }> => {
    return Object.keys(fields).reduce((acc, key) => {
      acc[key] = { selectedId: fields[key]?.selectedId || "" };
      return acc;
    }, {} as Record<string, { selectedId: string }>);
  };

  const methods = useForm<CustomerFormData>({
    resolver: zodResolver(customerSchema),
    defaultValues: {
      general: {
        name: data?.general.name || "",
        code: data?.general.code || "",
        ...mapGeneralFields({
          customerAccount: data?.general.customerAccount,
          naics: data?.general.naics,
          censusAccount: data?.general.censusAccount,
          commissionAccount: data?.general.commissionAccount,
          commissionEmployee: data?.general.commissionEmployee,
          documentationProfile: data?.general.documentationProfile,
          notificationProfile: data?.general.notificationProfile,
          billingAddressProfile: data?.general.billingAddressProfile,
          destinationProfile: data?.general.destinationProfile,
          orderProfile: data?.general.orderProfile,
          soilProfile: data?.general.soilProfile,
          statementProfile: data?.general.statementProfile,
          customerServiceRep: data?.general.customerServiceRep,
          parentCustomer: data?.general.parentCustomer,
          contractProfile: data?.general.contractProfile,
        }),
      },
    },
  });

  useEffect(() => {
    if (data) {
      methods.reset({
        general: {
          name: data.general.name,
          code: data.general.code,
          ...mapGeneralFields({
            customerAccount: data.general.customerAccount,
            naics: data.general.naics,
            censusAccount: data.general.censusAccount,
            commissionAccount: data.general.commissionAccount,
            commissionEmployee: data.general.commissionEmployee,
            documentationProfile: data.general.documentationProfile,
            notificationProfile: data.general.notificationProfile,
            billingAddressProfile: data.general.billingAddressProfile,
            destinationProfile: data.general.destinationProfile,
            orderProfile: data.general.orderProfile,
            soilProfile: data.general.soilProfile,
            statementProfile: data.general.statementProfile,
            customerServiceRep: data.general.customerServiceRep,
            parentCustomer: data.general.parentCustomer,
            contractProfile: data.general.contractProfile,
          }),
        },
        shipping: { ...data.shipping },
        billing: { ...data.billing },
        accounting: { ...data.accounting },
        items: data.items.items.map((item) => ({
          eerpType: {
            selectedId: item.eerpType?.selectedId || "",
          },
          commissionEmployee: {
            selectedId: item.commissionEmployee?.selectedId || "",
          },
          commissionAccount: {
            selectedId: item.commissionAccount?.selectedId || "",
          },
        })),
        garments: {
          replaceWhenDamaged: {
            selectedId: data.garments.replaceWhenDamaged.selectedId || "",
          },
          mendingAndRepairs: {
            selectedId: data.garments.mendingAndRepairs.selectedId || "",
          },
          replaceWhenStained: {
            selectedId: data.garments.replaceWhenStained.selectedId || "",
          },
          limitUnassignedGarments: data.garments.limitUnassignedGarments || false,
        },
        routing: {
          ...data.routing,
          stopPriority: {
            selectedId: data.routing.stopPriority.selectedId || "",
          },
          preferredWindowPriority: {
            selectedId: data.routing.preferredWindowPriority.selectedId || "",
          },
        },
        overrideRemittance: { ...data.overrideRemittance },
      });
    }
  }, [data, methods]);

  const billingAddressProfileWatch = methods.watch("general.billingAddressProfile.selectedId");

  useEffect(() => {
    if (billingAddressProfileWatch === "0") {
      setSteps(StepsWithBillingState);
    } else {
      setSteps(StepsInitialState);
    }
  }, [billingAddressProfileWatch]);

  const submit: SubmitHandler<CustomerFormData> = (newData) => {
    const customerVm: CustomerVM = {
      general: {
        ...data.general,
        ...newData.general,
        customerAccount: {
          ...data.general.customerAccount,
          selectedId: newData.general.customerAccount.selectedId,
        },
        naics: {
          ...data.general.naics,
          selectedId: newData.general.naics.selectedId,
        },
        censusAccount: {
          ...data.general.censusAccount,
          selectedId: newData.general.censusAccount.selectedId,
        },
        commissionAccount: {
          ...data.general.commissionAccount,
          selectedId: newData.general.commissionAccount.selectedId,
        },
        commissionEmployee: {
          ...data.general.commissionEmployee,
          selectedId: newData.general.commissionEmployee.selectedId,
        },
        documentationProfile: {
          ...data.general.documentationProfile,
          selectedId: newData.general.documentationProfile.selectedId,
        },
        notificationProfile: {
          selectedId: newData.general.notificationProfile.selectedId,
        },
        billingAddressProfile: {
          ...data.general.billingAddressProfile,
          selectedId: newData.general.billingAddressProfile.selectedId,
        },
        destinationProfile: {
          ...data.general.destinationProfile,
          selectedId: newData.general.destinationProfile.selectedId,
        },
        orderProfile: {
          ...data.general.orderProfile,
          selectedId: newData.general.orderProfile.selectedId,
        },
        soilProfile: {
          ...data.general.soilProfile,
          selectedId: newData.general.soilProfile.selectedId,
        },
        statementProfile: {
          ...data.general.statementProfile,
          selectedId: newData.general.statementProfile.selectedId,
        },
        customerServiceRep: {
          ...data.general.customerServiceRep,
          selectedId: newData.general.customerServiceRep.selectedId,
        },
        parentCustomer: {
          ...data.general.parentCustomer,
          selectedId: newData.general.parentCustomer.selectedId,
        },
      },
      shipping: { ...data.shipping, ...newData.shipping },
      billing: { ...data.billing, ...newData.billing },
      accounting: { ...data.accounting, ...newData.accounting },
      items: {
        items: newData.items.map((item, index) => ({
          ...data.items.items[index],
          ...item,
          eerpType: {
            ...data.items.items[index].eerpType,
            selectedId: item.eerpType.selectedId,
          },
          commissionEmployee: {
            ...data.items.items[index].commissionEmployee,
            selectedId: item.commissionEmployee.selectedId,
          },
          commissionAccount: {
            ...data.items.items[index].commissionAccount,
            selectedId: item.commissionAccount.selectedId,
          },
        })),
      },
      garments: {
        ...data.garments,
        ...newData.garments,
        replaceWhenDamaged: {
          ...data.garments.replaceWhenDamaged,
          selectedId: newData.garments.replaceWhenDamaged.selectedId,
        },
        mendingAndRepairs: {
          ...data.garments.mendingAndRepairs,
          selectedId: newData.garments.mendingAndRepairs.selectedId,
        },
        replaceWhenStained: {
          ...data.garments.replaceWhenStained,
          selectedId: newData.garments.replaceWhenStained.selectedId,
        },
      },
      routing: {
        ...data.routing,
        ...newData.routing,
        stopPriority: {
          ...data.routing.stopPriority,
          selectedId: newData.routing.stopPriority.selectedId,
        },
        preferredWindowPriority: {
          ...data.routing.preferredWindowPriority,
          selectedId: newData.routing.preferredWindowPriority.selectedId,
        },
      },
      documentation: { ...data.documentation },
      entity: { ...data.entity },
      notes: { ...data.notes },
      overrideRemittance: { ...data.overrideRemittance, ...newData.overrideRemittance },
    };

    updateCustomer(
      { id: Number(id), customerVm },
      {
        onSuccess: () => {
          setToast(dispatch, {
            open: true,
            message: "Customer updated successfully",
            snackbarProps: {
              autoHideDuration: 3500,
            },
            alertProps: {
              severity: "success",
            },
          });
        },
        onError: (error: any) => {
          const errors = error.body?.errors || {};
          const flattenedErrors = Object.entries(errors);

          if (flattenedErrors.length > 0) {
            const firstErrorKey = flattenedErrors[0][0].split(".")[0];
            const tab = steps.find((step) => step.toLowerCase() === firstErrorKey.toLowerCase());
            if (tab) {
              setActiveStep(steps.findIndex((step) => step.toLowerCase() === tab.toLowerCase()));
            }
          }

          const messages = flattenedErrors.flatMap(
            ([_, errorMessages]) => errorMessages as string[]
          );
          setErrorBEMessages(messages);

          setToast(dispatch, {
            open: true,
            message: "Failed to update customer",
            snackbarProps: {
              autoHideDuration: 3500,
            },
          });
        },
      }
    );
  };

  function getStepContent(stepIndex: number): JSX.Element {
    switch (steps[stepIndex]) {
      case StepsENUM.GENERAL:
        return <GeneralCustomerInfo {...data.general} />;
      case StepsENUM.BILLING:
        return <BillingCustomerInfo />;
      case StepsENUM.SHIPPING:
        return <ShippingInfo />;
      case StepsENUM.ACCOUNTING:
        return <CustomerAccountingInfo {...data.accounting} />;
      case StepsENUM.ITEMS:
        return <Items {...data.items} />;
      case StepsENUM.GARMENTS:
        return <CustomerGarmentsInfo {...data.garments} />;
      case StepsENUM.NOTES:
        return <CustomerNotes {...data.notes} />;
      case StepsENUM.ROUTING:
        return <RoutingInfo {...data.routing} />;
      case StepsENUM.DOCUMENTATION:
        return <CustomerDocumentation {...data.documentation} />;
      case StepsENUM.OVERRIDE_REMITTANCE:
        return <CustomerOverrideRemittance />;
      default:
        return null;
    }
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };

  const handleFormError = (errors: typeof methods.formState.errors) => {
    const firstErrorKey = Object.keys(errors)[0].toLowerCase();

    const tabMapping: Record<string, number> = steps.reduce((acc, step, index) => {
      acc[step.toLowerCase().replace(" ", "")] = index;
      return acc;
    }, {} as Record<string, number>);

    if (firstErrorKey && tabMapping[firstErrorKey] !== undefined) {
      setActiveStep(tabMapping[firstErrorKey]);
    }
  };

  return (
    <CustomerLayout>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 130px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {isFetched && (
        <>
          <Box mx={1} my={2}>
            <Tabs
              value={activeStep}
              onChange={handleTabChange}
              aria-label="Customer Details options"
            >
              {steps.map((label, index) => (
                <Tab label={label} key={index} />
              ))}
            </Tabs>
          </Box>
          <Box pt={2} px={2}>
            <Header customer={data.entity} />
            {errorBEMessages.length > 0 && (
              <Box py={2}>
                <Alert severity="error" variant="filled">
                  <AlertTitle>Errors</AlertTitle>
                  <ol>
                    {errorBEMessages.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ol>
                </Alert>
              </Box>
            )}
          </Box>
          <Box p={2}>
            <Box>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submit, handleFormError)}>
                  {getStepContent(activeStep)}
                  <Box mt={3} width="100%" display="flex" justifyContent="space-between">
                    <Button type="submit" variant="contained" color="info" loading={isPending}>
                      Submit
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          </Box>
        </>
      )}
    </CustomerLayout>
  );
}

export default CustomerDetails;
