import { useEffect, JSXElementConstructor, Key, ReactElement } from "react";
import { useAuth } from "react-oidc-context";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import theme from "assets/theme";
import { routes, publicRoutes } from "routes";
import { useMaterialUIController, setLoggedInProfile, setDomainLaundryModal } from "context";

import Sidebar from "components/Sidebar";
import DomainLaundryModal from "components/DomainLaundryModal";
import Header from "components/Header";
import { OpenAPI } from "services/information_manager_api/generated";
import { DOMAIN_COOKIE_KEY, LAUNDRY_COOKIE_KEY, getCookie } from "utils/cookies";
import logger from "./utils/logger";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { loggedInProfile, domainLaundryModal } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  logger.debug("App component rendering");

  // Set up authentication token
  useEffect(() => {
    if (auth.user) {
      OpenAPI.TOKEN = auth.user.access_token;
    }
  }, [auth.user]);

  // Check and load domain/laundry from cookies on startup
  useEffect(() => {
    if (auth.isAuthenticated && !loggedInProfile?.domain) {
      logger.debug("Checking cookies for domain/laundry", {
        isAuthenticated: auth.isAuthenticated,
        hasProfile: !!loggedInProfile?.domain,
      });

      const savedDomain = getCookie(DOMAIN_COOKIE_KEY);
      const savedLaundry = getCookie(LAUNDRY_COOKIE_KEY);
      const domains = Array.isArray(auth.user?.profile.domains) ? auth.user?.profile?.domains : [];

      logger.debug("Cookie values", {
        savedDomain,
        savedLaundry,
        availableDomains: domains,
      });

      if (savedDomain && savedLaundry && domains.includes(savedDomain)) {
        const newProfile = {
          cognitoProfile: { ...auth.user?.profile },
          domain: savedDomain,
          laundry: savedLaundry,
          email: auth.user?.profile.email || "",
        };
        logger.info("Setting domain/laundry from cookies", newProfile);
        setLoggedInProfile(dispatch, newProfile);
        OpenAPI.DOMAIN = savedDomain;
        OpenAPI.LAUNDRY = savedLaundry;
      } else {
        logger.warn("No valid domain/laundry found in cookies", {
          hasDomain: !!savedDomain,
          hasLaundry: !!savedLaundry,
          isDomainValid: domains.includes(savedDomain || ""),
        });
        setDomainLaundryModal(dispatch, true);
      }
    }
  }, [auth.isAuthenticated, auth.user]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  useEffect(() => {
    logger.info("App mounted");
    if (auth && !auth.isAuthenticated) auth.signinRedirect();
  }, []);

  const handleCloseDomainLaundryModal = () => {
    setDomainLaundryModal(dispatch, false);
    navigate(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Box component="main" sx={{ display: "flex" }}>
        <Sidebar />
        <Box sx={{ flex: 1 }}>
          <Routes>
            {getRoutes(auth.isAuthenticated ? routes : publicRoutes)}
            {auth.isAuthenticated && <Route path="*" element={<Navigate to={pathname} />} />}
          </Routes>
        </Box>
      </Box>
      {auth.isAuthenticated && (
        <DomainLaundryModal open={domainLaundryModal} onClose={handleCloseDomainLaundryModal} />
      )}
    </ThemeProvider>
  );
}
