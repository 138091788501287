import { useMutation } from "@tanstack/react-query";
import { LmcustomersService } from "services/information_manager_api/generated/services/LmcustomersService";
import { CustomerVM } from "services/information_manager_api/generated";
import { CustomerAccountsService } from "services/information_manager_api/generated/services/CustomerAccountsService";

type UpdateCustomerParams = {
  id: number;
  customerVm: CustomerVM;
};

export const useUpdateCustomer = () => {
  return useMutation({
    mutationFn: ({ id, customerVm }: UpdateCustomerParams) =>
      LmcustomersService.putApiLmcustomersVm(id, customerVm),
  });
};

export const usePostCustomerAccount = () => {
  return useMutation({
    mutationFn: ({ customerId, requestBody }: any) =>
      CustomerAccountsService.postApiCustomersAccountsVm(customerId, requestBody),
  });
};

export const useDeleteCustomerAccount = () => {
  return useMutation({
    mutationFn: ({ accountId }: any) =>
      CustomerAccountsService.deleteApiCustomersAccounts(accountId),
  });
};
