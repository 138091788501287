import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";

type Types = any;

const listItemButton: Types = {
  styleOverrides: {
    root: {
      gap: pxToRem(8),
      "&.Mui-selected": {
        backgroundColor: colors.grey[300],
        "&:hover": {
          backgroundColor: colors.grey[300],
        },
      },
    },
  },
};

export default listItemButton;
