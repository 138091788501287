import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useDocumentsDownload } from "hooks/useDocuments";
import logger from "utils/logger";

interface DocumentDownloadButtonProps {
  id: number;
  fileName: string;
}

const DocumentDownloadButton: React.FC<DocumentDownloadButtonProps> = ({ id, fileName }) => {
  const { data, error, isLoading } = useDocumentsDownload({ id: id });
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    logger.debug("Download attempted", { id, fileName, hasData: !!data, isLoading });

    if (!data) {
      logger.error("No data available for download", { id, fileName });
      return;
    }

    setLoading(true);
    try {
      const element = document.createElement("a");
      const file = new Blob([data], { type: "application/octet-stream" });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      logger.debug("Download completed successfully", { id, fileName });
    } catch (err) {
      logger.error("Error during file download", { id, fileName, error: err });
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    logger.error("Document download error", { id, fileName, error });
    return <div>Error loading document</div>;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      disabled={loading || isLoading || !data}
    >
      {loading || isLoading ? <CircularProgress size={24} /> : "Download"}
    </Button>
  );
};

export default DocumentDownloadButton;
