import { z } from "zod";

const DropdownSelectedId = (fieldName: string) =>
  z.object({
    selectedId: z.string().min(1, `${fieldName} is required`),
  });

const generalCustomerInfoSchema = z.object({
  name: z.string().min(1, "Name is required"),
  code: z.string().min(1, "Code is required"),
  customerAccount: DropdownSelectedId("Customer Account"),
  censusAccount: DropdownSelectedId("Census Account"),
  commissionAccount: DropdownSelectedId("Commission Account"),
  commissionEmployee: DropdownSelectedId("Commission Employee"),
  documentationProfile: DropdownSelectedId("Documentation Profile"),
  notificationProfile: DropdownSelectedId("Notification Profile"),
  naics: DropdownSelectedId("NAICS"),
  billingAddressProfile: DropdownSelectedId("Billing Address Profile"),
  destinationProfile: DropdownSelectedId("Destination Profile"),
  orderProfile: DropdownSelectedId("Order Profile"),
  soilProfile: DropdownSelectedId("Soil Profile"),
  parentCustomer: DropdownSelectedId("Parent Customer"),
  statementProfile: DropdownSelectedId("Statement Profile"),
  customerServiceRep: DropdownSelectedId("Customer Service Rep"),
  contractProfile: DropdownSelectedId("Contract Profile"),
});

const billingInfoSchema = z.object({
  address: z.object({
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    state: z.string(),
    zipcode: z.string(),
  }),
  bcc: z.string(),
  contact: z.string(),
  email: z.string(),
  fax: z.string(),
  phone: z.string(),
});

const shippingInfoSchema = z.object({
  address: z.object({
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    state: z.string(),
    zipcode: z.string(),
  }),
  bcc: z.string(),
  contact: z.string(),
  email: z.string(),
  fax: z.string(),
  fipsCode: z.string(),
  phone: z.string(),
});

const accountingInfoSchema = z.object({
  accountsReceivableCode: z.string(),
  activationDate: z
    .string()
    .refine(
      (date) => new Date(date) >= new Date(1970, 0, 1),
      "Activation date must be on or after 01/01/1970"
    ),
  blanketPurchaseOrder: z.string(),
  creditLimit: z.number({
    invalid_type_error: "Credit Limit must be a number",
  }),
  deactivationDate: z
    .string()
    .refine(
      (date) => new Date(date) >= new Date(1970, 0, 1),
      "Deactivation date must be on or after 01/01/1970"
    ),
  dunsNumber: z.string(),
  ediVanOptions: DropdownSelectedId("EDI VAN Options"),
  invoiceMinimumOverrideAmount: z.number(),
  invoiceReference: z.string(),
  overrideInvoiceMinimum: z.boolean(),
  overrideSalesAccountCode: z.string(),
});

const itemsSchema = z.array(
  z.object({
    eerpType: DropdownSelectedId("EERP Type"),
    commissionEmployee: DropdownSelectedId("Commission Employee"),
    commissionAccount: DropdownSelectedId("Commission Account"),
  })
);

const garmentsSchema = z.object({
  replaceWhenDamaged: DropdownSelectedId("Replace When Damaged"),
  mendingAndRepairs: DropdownSelectedId("Mending and Repairs"),
  replaceWhenStained: DropdownSelectedId("Replace When Stained"),
  limitUnassignedGarments: z.boolean(),
});

const routingInfoSchema = z
  .object({
    isSundayAvailable: z.boolean(),
    isMondayAvailable: z.boolean(),
    isTuesdayAvailable: z.boolean(),
    isWednesdayAvailable: z.boolean(),
    isThursdayAvailable: z.boolean(),
    isFridayAvailable: z.boolean(),
    isSaturdayAvailable: z.boolean(),
    availableDeliveryTimeStart: z.string(),
    availableDeliveryTimeEnd: z.string(),
    firstPreferredDeliveryWindowTimeStart: z.string(),
    firstPreferredDeliveryWindowTimeEnd: z.string(),
    secondPreferredDeliveryWindowTimeStart: z.string(),
    secondPreferredDeliveryWindowTimeEnd: z.string(),
    averageDeliveryDuration: z.string(),
    stopPriority: DropdownSelectedId("Stop Priority"),
    preferredWindowPriority: DropdownSelectedId("Preferred Window Priority"),
  })
  .superRefine((data, ctx) => {
    if (data.availableDeliveryTimeStart > data.availableDeliveryTimeEnd) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Available delivery time start must be earlier than end time",
        path: ["availableDeliveryTimeStart"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Available delivery time end must be later than start time",
        path: ["availableDeliveryTimeEnd"],
      });
    }
    if (data.firstPreferredDeliveryWindowTimeStart > data.firstPreferredDeliveryWindowTimeEnd) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "First preferred delivery window start must be earlier than end time",
        path: ["firstPreferredDeliveryWindowTimeStart"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "First preferred delivery window end must be later than start time",
        path: ["firstPreferredDeliveryWindowTimeEnd"],
      });
    }
    if (data.secondPreferredDeliveryWindowTimeStart > data.secondPreferredDeliveryWindowTimeEnd) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Second preferred delivery window start must be earlier than end time",
        path: ["secondPreferredDeliveryWindowTimeStart"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Second preferred delivery window end must be later than start time",
        path: ["secondPreferredDeliveryWindowTimeEnd"],
      });
    }
    if (data.availableDeliveryTimeStart > data.firstPreferredDeliveryWindowTimeStart) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Available delivery time start must be earlier than the first preferred window start",
        path: ["availableDeliveryTimeStart"],
      });
    }
    if (data.availableDeliveryTimeEnd < data.firstPreferredDeliveryWindowTimeEnd) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Available delivery time end must be later than the first preferred window end",
        path: ["availableDeliveryTimeEnd"],
      });
    }
    if (data.availableDeliveryTimeStart > data.secondPreferredDeliveryWindowTimeStart) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Available delivery time start must be earlier than the second preferred window start",
        path: ["availableDeliveryTimeStart"],
      });
    }
    if (data.availableDeliveryTimeEnd < data.secondPreferredDeliveryWindowTimeEnd) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Available delivery time end must be later than the second preferred window end",
        path: ["availableDeliveryTimeEnd"],
      });
    }
  });

const overrideRemittance = z
  .object({
    name: z.string(),
    address: z.object({
      address1: z.string(),
      address2: z.string(),
      city: z.string(),
      state: z.string(),
      zipcode: z.string(),
    }),
    overrideLaundryRemittance: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.overrideLaundryRemittance && !data.name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Name is required",
        path: ["name"],
      });
    }
    if (data.overrideLaundryRemittance && !data.address?.address1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Address 1 is required",
        path: ["address.address1"],
      });
    }
    if (data.overrideLaundryRemittance && !data.address?.city) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "City is required",
        path: ["address.city"],
      });
    }
    if (data.overrideLaundryRemittance && !data.address?.state) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "State is required",
        path: ["address.state"],
      });
    }
    if (data.overrideLaundryRemittance && !data.address?.zipcode) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Zip Code is required",
        path: ["address.zipcode"],
      });
    }
  });

export const customerSchema = z
  .object({
    general: generalCustomerInfoSchema,
    billing: billingInfoSchema,
    shipping: shippingInfoSchema,
    accounting: accountingInfoSchema,
    items: itemsSchema,
    garments: garmentsSchema,
    routing: routingInfoSchema,
    overrideRemittance: overrideRemittance,
  })
  .superRefine((data, ctx) => {
    const isDifferentFromBilling = data.general.billingAddressProfile.selectedId === "0";
    if (isDifferentFromBilling) {
      if (!data.billing.address.address1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Address 1 is required when using a different billing address",
          path: ["billing", "address", "address1"],
        });
      }
      if (!data.billing.address.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "City is required when using a different billing address",
          path: ["billing", "address", "city"],
        });
      }
      if (!data.billing.address.state) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "State is required when using a different billing address",
          path: ["billing", "address", "state"],
        });
      }
      if (!data.billing.address.zipcode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Zip Code is required when using a different billing address",
          path: ["billing", "address", "zipcode"],
        });
      }
    }
  });

export type CustomerFormData = z.infer<typeof customerSchema>;
