import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { setLoggedInProfile, useMaterialUIController, setToast } from "context";
import { LmlaundriesService } from "services/information_manager_api/generated/services/LmlaundriesService";
import type { Lmlaundry } from "services/information_manager_api/generated/models/Lmlaundry";
import { OpenAPI } from "services/information_manager_api/generated";
import { DOMAIN_COOKIE_KEY, LAUNDRY_COOKIE_KEY, getCookie, setCookie } from "utils/cookies";
import { signOutRedirect } from "utils/signout";

interface DomainLaundryModalProps {
  open: boolean;
  onClose: () => void;
}

const DomainLaundryModal = ({ open, onClose }: DomainLaundryModalProps) => {
  const [controller, dispatch] = useMaterialUIController();
  const [laundries, setLaundries] = useState<Lmlaundry[]>([]);
  const [errorLaundries, setErrorLaundries] = useState<boolean>(false);
  const [isLoadingLaundries, setIsLoadingLaundries] = useState<boolean>(false);
  const { loggedInProfile } = controller;

  const auth = useAuth();
  const domains: string[] = Array.isArray(auth.user?.profile.domains)
    ? auth.user?.profile?.domains
    : [];

  // Load domain and laundry from cookies on initial mount
  useEffect(() => {
    const savedDomain = getCookie(DOMAIN_COOKIE_KEY);
    const savedLaundry = getCookie(LAUNDRY_COOKIE_KEY);

    if (savedDomain && domains.includes(savedDomain)) {
      updateSelectedDomain(savedDomain);
    } else if (domains.length === 1) {
      updateSelectedDomain(domains[0]);
    }

    if (savedLaundry) {
      // We'll verify if this laundry exists after fetching laundries
      updateSelectedLaundry(savedLaundry);
    }
  }, [domains]);

  // Fetch laundries when a domain is selected/changed
  useEffect(() => {
    if (loggedInProfile?.domain) {
      setIsLoadingLaundries(true);
      setErrorLaundries(false);
      LmlaundriesService.getApiLmlaundriesByUser()
        .then((response) => {
          setLaundries(response);
          // If we have a selected laundry from cookies, verify it exists
          if (loggedInProfile.laundry) {
            const laundryExists = response.some((l) => l.name === loggedInProfile.laundry);
            if (!laundryExists) {
              updateSelectedLaundry("");
            }
          } else if (response.length === 1) {
            updateSelectedLaundry(response[0].name || "");
          } else if (response.length === 0) {
            setToast(dispatch, {
              open: true,
              message: "You have no laundries assigned to your user",
              snackbarProps: {
                autoHideDuration: 3500,
              },
            });
            setErrorLaundries(true);
          }
          setIsLoadingLaundries(false);
          setErrorLaundries(false);
        })
        .catch((error) => {
          console.error("Failed to fetch laundries:", error);
          setToast(dispatch, {
            open: true,
            message: "Failed to fetch laundries",
            snackbarProps: {
              autoHideDuration: 3500,
            },
          });
          setIsLoadingLaundries(false);
          setErrorLaundries(true);
        });
    }
  }, [loggedInProfile?.domain]);

  const updateSelectedDomain = (selectedDomain: string) => {
    //Return if the domain is already selected
    if (loggedInProfile?.domain === selectedDomain) {
      return;
    }
    var newProfile = loggedInProfile
      ? { ...loggedInProfile, domain: selectedDomain }
      : { cognitoProfile: { ...auth.user?.profile }, domain: selectedDomain };

    setLoggedInProfile(dispatch, newProfile);
    OpenAPI.DOMAIN = selectedDomain;
    setCookie(DOMAIN_COOKIE_KEY, selectedDomain);
  };

  const updateSelectedLaundry = (selectedLaundry: string) => {
    var newProfile = loggedInProfile
      ? { ...loggedInProfile, laundry: selectedLaundry }
      : { cognitoProfile: { ...auth.user?.profile }, laundry: selectedLaundry };
    setLoggedInProfile(dispatch, newProfile);
    OpenAPI.LAUNDRY = selectedLaundry;
    setCookie(LAUNDRY_COOKIE_KEY, selectedLaundry);
  };

  const domainAndLaundrySelected =
    OpenAPI.DOMAIN && OpenAPI.LAUNDRY && loggedInProfile?.domain && loggedInProfile?.laundry;

  const SignoutElement = (
    <Button variant="outlined" color="error" onClick={() => signOutRedirect(auth)}>
      Sign out
    </Button>
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md" disableEscapeKeyDown>
      <DialogTitle>Domain and Laundry</DialogTitle>
      <DialogContent dividers>
        <p>Please select a domain and laundry to continue.</p>
        <Box pt={3} pb={3}>
          <Box component="form" role="form">
            <Typography variant="h6" fontWeight="medium" mb={2}>
              Domain
            </Typography>
            <Select
              value={loggedInProfile?.domain || ""}
              onChange={(e) => updateSelectedDomain(e.target.value as string)}
              displayEmpty
              sx={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                "&:focus": {
                  borderColor: "primary.main",
                },
              }}
            >
              <MenuItem value="" disabled>
                Select a domain
              </MenuItem>
              {domains.map((domain) => (
                <MenuItem key={domain} value={domain}>
                  {domain}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box pb={3}>
          {isLoadingLaundries && <Skeleton variant="rounded" sx={{ fontSize: "4rem" }} />}
          {loggedInProfile?.domain && laundries && laundries.length > 0 && !isLoadingLaundries && (
            <Box component="form" role="form">
              <Typography variant="h6" fontWeight="medium" mb={2}>
                Laundry
              </Typography>
              <Select
                value={loggedInProfile?.laundry || ""}
                onChange={(e) => updateSelectedLaundry(e.target.value as string)}
                displayEmpty
                sx={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  "&:focus": {
                    borderColor: "primary.main",
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select a laundry
                </MenuItem>
                {laundries.map((laundry: Lmlaundry) => (
                  <MenuItem key={laundry.name} value={laundry.name}>
                    {laundry.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {errorLaundries && (
            <Alert variant="outlined" severity="error" action={SignoutElement}>
              An error occured when trying to fetch laundries
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} disabled={!domainAndLaundrySelected}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DomainLaundryModal;
