import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme//base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

const { grey } = colors;
const { size } = typography;
const { borderColor, borderWidth } = borders;
const {
  values: { sm },
} = breakpoints;

const SM = `@media (min-width: ${sm}px)`;

type Types = any;

const breadcrumbs: Types = {
  styleOverrides: {
    root: {
      backgroundColor: colors.white.main,
      borderTop: `${borderWidth[1]} solid ${borderColor}`,
      borderBottom: `${borderWidth[1]} solid ${borderColor}`,
      padding: `${pxToRem(8)} ${pxToRem(16)}`,

      [SM]: {
        padding: `${pxToRem(8)} ${pxToRem(24)}`,
      },
    },
    li: {
      lineHeight: 0,
      fontSize: size.sm,
    },

    separator: {
      fontSize: size.sm,
      color: grey[600],
    },
  },
};

export default breadcrumbs;
