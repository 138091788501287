import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomerLayout from "pages/customer/layouts";

const CustomerOrders = () => {
  return (
    <CustomerLayout>
      <Box sx={{ m: 3 }}>
        <Typography variant="h2">Customer Orders</Typography>
      </Box>
    </CustomerLayout>
  );
};

export default CustomerOrders;
