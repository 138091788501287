import { useFormContext } from "react-hook-form";
import { Card, Box, Typography } from "@mui/material";
import { TextField, FormControlLabel, Checkbox, Grid2 as Grid } from "@mui/material";

import { CustomerFormData } from "schemas/customerSchema";

const CustomerOverrideRemittance = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CustomerFormData>();

  return (
    <Card>
      <Box p={3}>
        <Typography variant="h5">Override Remittance</Typography>
        <Box mt={1}>
          <Grid container spacing={3}>
            <Grid size={6}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Name"
                value={watch("overrideRemittance.name")}
                variant="outlined"
                fullWidth
                {...register("overrideRemittance.name")}
                error={!!errors.overrideRemittance?.name}
                helperText={errors.overrideRemittance?.name?.message || ""}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="City"
                value={watch("overrideRemittance.address.city")}
                variant="outlined"
                fullWidth
                {...register("overrideRemittance.address.city")}
                error={!!errors.overrideRemittance?.address?.city}
                helperText={errors.overrideRemittance?.address?.city?.message || ""}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Address 1"
                value={watch("overrideRemittance.address.address1")}
                variant="outlined"
                fullWidth
                {...register("overrideRemittance.address.address1")}
                error={!!errors.overrideRemittance?.address?.address1}
                helperText={errors.overrideRemittance?.address?.address1?.message || ""}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="State"
                value={watch("overrideRemittance.address.state")}
                variant="outlined"
                fullWidth
                {...register("overrideRemittance.address.state")}
                error={!!errors.overrideRemittance?.address?.state}
                helperText={errors.overrideRemittance?.address?.state?.message || ""}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Address 2"
                value={watch("overrideRemittance.address.address2")}
                variant="outlined"
                fullWidth
                {...register("overrideRemittance.address.address2")}
                error={!!errors.overrideRemittance?.address?.address2}
                helperText={errors.overrideRemittance?.address?.address2?.message || ""}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                slotProps={{ inputLabel: { shrink: true } }}
                label="Zip Code"
                value={watch("overrideRemittance.address.zipcode")}
                variant="outlined"
                fullWidth
                {...register("overrideRemittance.address.zipcode")}
                error={!!errors.overrideRemittance?.address?.zipcode}
                helperText={errors.overrideRemittance?.address?.zipcode?.message || ""}
              />
            </Grid>
            <Grid size={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={watch("overrideRemittance.overrideLaundryRemittance")}
                    {...register("overrideRemittance.overrideLaundryRemittance")}
                    color="primary"
                  />
                }
                label="Override Laundry Remittance"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default CustomerOverrideRemittance;
