import { DefaultCell } from "components/Grid";

const ellipsisStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

interface EllipsisCellProps {
  value: any;
  maxWidth: string;
}

const EllipsisCell: React.FC<EllipsisCellProps> = ({ value, maxWidth }) => (
  <div style={{ ...ellipsisStyle, maxWidth }} title={value}>
    <DefaultCell value={value} />
  </div>
);

const dataTableData = {
  columns: [
    {
      Header: "Note Type",
      accessor: "noteTypeLabel",
      Cell: ({ value }: { value: any }) => <DefaultCell value={value} />,
    },
    {
      Header: "Subject",
      accessor: "subject",
      Cell: ({ value }: { value: any }) => <EllipsisCell value={value} maxWidth="14rem" />,
    },
    {
      Header: "Note Text",
      accessor: "text",
      Cell: ({ value }: { value: any }) => <EllipsisCell value={value} maxWidth="40rem" />,
    },
    {
      Header: "Audit Trail",
      accessor: "auditTrailLabel",
      Cell: ({ value }: { value: any }) => <DefaultCell value={value} />,
      sortType: (rowA: any, rowB: any) =>
        new Date(rowA.original.createdAt).getTime() - new Date(rowB.original.createdAt).getTime(), // sort by createdAt chronologically
    },
  ],
  rows: [] as any[],
};

export default dataTableData;
