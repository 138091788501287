import { NavLink, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";

const Breadcrumb = () => {
  const location = useLocation();
  const routes = location.pathname.split("/").filter((i) => i);

  const StyledNavLink = styled(NavLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  }));

  const formatRoute = (route: string) => {
    return route.charAt(0).toUpperCase() + route.slice(1).replace(/-/g, " ");
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <StyledNavLink to="/">Home</StyledNavLink>
      {routes.map((route, index) => {
        const last = index === routes.length - 1;
        return last ? (
          <Box component="span" key={index}>
            {formatRoute(route)}
          </Box>
        ) : (
          <StyledNavLink key={index} to={`/${routes.slice(0, index + 1).join("/")}`}>
            {formatRoute(route)}
          </StyledNavLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
