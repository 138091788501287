import { useMaterialUIController, setToast } from "context";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Toast = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { toast } = controller;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast(dispatch, {
      ...toast,
      open: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      {...toast.snackbarProps}
      open={toast.open}
      onClose={handleClose}
    >
      <Alert
        severity="error"
        variant="filled"
        {...toast.alertProps}
        onClose={handleClose}
        sx={{ width: "100%" }}
      >
        {toast.message}
        {toast.action}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
