import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Logo from "components/Logo";
import CloseIcon from "@mui/icons-material/ClearOutlined";

import { routes } from "routes";
import { useMaterialUIController, setIsSidebarOpen } from "context";

import { StyledNavLink } from "./styles";

const Sidebar = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { isSidebarOpen } = controller;

  const handleClose = () => setIsSidebarOpen(dispatch, false);

  return (
    <Drawer open={isSidebarOpen} onClose={handleClose}>
      <Box
        sx={{
          px: 0.8,
          pt: 1,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Box role="presentation" onClick={handleClose}>
          <Logo />
        </Box>
      </Box>
      <Divider />
      <Box sx={{ width: 250 }} role="presentation" onClick={handleClose}>
        <List>
          {routes.map((route, index) => {
            if (route.type === "collapse") {
              return (
                <StyledNavLink to={route.route} key={route.key}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <route.icon />
                      </ListItemIcon>
                      <ListItemText primary={route.name} />
                    </ListItemButton>
                  </ListItem>
                </StyledNavLink>
              );
            }
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
