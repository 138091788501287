import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Lmcustomer } from "services/information_manager_api/generated";
interface HeaderProps {
  customer: Lmcustomer;
}

function Header({ customer }: HeaderProps): JSX.Element {
  return (
    <MDBox display="flex" justifyContent="space-between" alignItems="center">
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Customer Details for # {customer.rcId}
          </MDTypography>
        </MDBox>
        <MDTypography component="p" variant="button" color="text">
          {customer.name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Header;
