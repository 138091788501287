export const StepsENUM = {
  GENERAL: "General",
  SHIPPING: "Shipping",
  BILLING: "Billing",
  ACCOUNTING: "Accounting",
  ITEMS: "Items",
  GARMENTS: "Garments",
  ROUTING: "Routing",
  DOCUMENTATION: "Documentation",
  NOTES: "Notes",
  OVERRIDE_REMITTANCE: "Override Remittance",
};

export const StepsInitialState = [
  StepsENUM.GENERAL,
  StepsENUM.SHIPPING,
  StepsENUM.ACCOUNTING,
  StepsENUM.ITEMS,
  StepsENUM.GARMENTS,
  StepsENUM.ROUTING,
  StepsENUM.DOCUMENTATION,
  StepsENUM.NOTES,
  StepsENUM.OVERRIDE_REMITTANCE,
];

export const StepsWithBillingState = [
  StepsENUM.GENERAL,
  StepsENUM.BILLING,
  StepsENUM.SHIPPING,
  StepsENUM.ACCOUNTING,
  StepsENUM.ITEMS,
  StepsENUM.GARMENTS,
  StepsENUM.ROUTING,
  StepsENUM.NOTES,
  StepsENUM.OVERRIDE_REMITTANCE,
];
