import { useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Typography,
  Box,
  Grid2 as Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Button,
} from "@mui/material";
import CustomerLayout from "pages/customer/layouts";
import { useCustumerAccount } from "hooks/useCustomers";
import { usePostCustomerAccount } from "hooks/useUpdateCustomer";
import { useMaterialUIController, setToast } from "context";
import { zodResolver } from "@hookform/resolvers/zod";
import { accountSchema, type AccountFormData } from "schemas/account";

const CustomerAccountsEdit = () => {
  const { id, accountId } = useParams<{ id: string; accountId: string }>();
  const [context, dispatch] = useMaterialUIController();
  const { data } = useCustumerAccount({
    customerId: Number(id),
    accountId: Number(accountId),
  });
  const { mutate: postCustomerAccount, isPending } = usePostCustomerAccount();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AccountFormData>({
    resolver: zodResolver(accountSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const { type, code, description } = data;
      setValue("type", type);
      setValue("code", code);
      setValue("description", description);
    }
  }, [data]);

  const submit: SubmitHandler<AccountFormData> = (data) => {
    const { type, code, description } = data;

    const requestBody = {
      id: Number(accountId),
      type,
      code,
      description,
    };

    postCustomerAccount(
      { customerId: Number(id), requestBody },
      {
        onSuccess: () => {
          navigate(-1);
          setToast(dispatch, {
            open: true,
            message: "Customer Account updated successfully",
            snackbarProps: {
              autoHideDuration: 3500,
            },
            alertProps: {
              severity: "success",
            },
          });
        },
        onError: () => {
          setToast(dispatch, {
            open: true,
            message: "Failed to update a customer account",
            snackbarProps: {
              autoHideDuration: 3500,
            },
          });
        },
      }
    );
  };

  return (
    <CustomerLayout>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6" sx={{ m: 1 }}>
          Edit account #{accountId}
        </Typography>
        <form onSubmit={handleSubmit(submit)}>
          <Card>
            <Box p={3}>
              <Box mt={1}>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="type-label">Type</InputLabel>
                      <Select
                        labelId="type-label"
                        value={watch("type") || 1}
                        label="Type"
                        fullWidth
                        {...register("type", { valueAsNumber: true })}
                      >
                        <MenuItem value={1}>Cost</MenuItem>
                        <MenuItem value={2}>Census</MenuItem>
                        <MenuItem value={3}>Inventory</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <TextField
                      slotProps={{ inputLabel: { shrink: true } }}
                      label="Code*"
                      value={watch("code")}
                      variant="outlined"
                      fullWidth
                      {...register("code")}
                      error={!!errors.code}
                      helperText={errors.code?.message}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TextField
                      slotProps={{ inputLabel: { shrink: true } }}
                      label="Description*"
                      value={watch("description")}
                      variant="outlined"
                      fullWidth
                      {...register("description")}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
          <Stack
            direction="row"
            sx={{ mt: 2, justifyContent: "space-between", alignItems: "center" }}
          >
            <Button variant="contained" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" loading={isPending}>
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </CustomerLayout>
  );
};

export default CustomerAccountsEdit;
