import CustomerList from "pages/customer/list";
import CustomerDetails from "pages/customer/details";
import CustomerAccounts from "pages/customer/accounts";
import CustomerAccountsAdd from "pages/customer/accounts/Add";
import CustomerAccountsEdit from "pages/customer/accounts/Edit";
import CustomerContracts from "pages/customer/contracts";
import CustomerOrders from "pages/customer/orders";
import CustomerSoils from "pages/customer/soils";
import CustomerDestinations from "pages/customer/destinations";
import Login from "pages/login";

import { HomeSharp } from "@mui/icons-material";

const publicRoutes = [
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <Login />,
  },
];
const routes = [
  {
    type: "collapse",
    name: "Customers",
    key: "customer-list",
    route: "/customers",
    icon: HomeSharp,
    component: <CustomerList />,
    noCollapse: true,
  },
  {
    name: "Customer Details",
    key: "order-details",
    route: "/customers/:id",
    component: <CustomerDetails />,
  },
  {
    name: "Customer Accounts",
    key: "customer-accounts",
    route: "/customers/:id/accounts",
    component: <CustomerAccounts />,
  },
  {
    name: "Customer Accounts Add",
    key: "customer-accounts-add",
    route: "/customers/:id/accounts/add",
    component: <CustomerAccountsAdd />,
  },
  {
    name: "Customer Accounts Edit",
    key: "customer-accounts-edit",
    route: "/customers/:id/accounts/:accountId",
    component: <CustomerAccountsEdit />,
  },
  {
    name: "Customer Contracts",
    key: "customer-contracts",
    route: "/customers/:id/contracts",
    component: <CustomerContracts />,
  },
  {
    name: "Customer Orders",
    key: "customer-orders",
    route: "/customers/:id/orders",
    component: <CustomerOrders />,
  },
  {
    name: "Customer Soils",
    key: "customer-soils",
    route: "/customers/:id/soils",
    component: <CustomerSoils />,
  },
  {
    name: "Customer Destinations",
    key: "customer-destinations",
    route: "/customers/:id/destinations",
    component: <CustomerDestinations />,
  },
];

export { routes, publicRoutes };
