/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DBV_Note_Type {
  "_1" = 1,
  "_2" = 2,
  "_3" = 3,
  "_4" = 4,
  "_5" = 5,
  "_6" = 6,
}
