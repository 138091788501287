import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import MDInput from "components/MDInput";

interface Props {
  input?: {
    [key: string]: any;
  };
  [key: string]: any;
  error?: boolean;
  helperText?: string;
}

function MDDatePicker({ input, error, helperText, ...rest }: Props): JSX.Element {
  return (
    <>
      <Flatpickr
        {...rest}
        render={({ defaultValue }: any, ref: any) => (
          <MDInput {...input} defaultValue={defaultValue} inputRef={ref} error={error} />
        )}
      />
      {helperText && (
        <div style={{ color: error ? "red" : "inherit", fontSize: "0.75rem", marginTop: "4px" }}>
          {helperText}
        </div>
      )}
    </>
  );
}

MDDatePicker.defaultProps = {
  input: {},
  error: false,
  helperText: "",
};

export default MDDatePicker;
