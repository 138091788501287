import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDeleteCustomerAccount } from "hooks/useUpdateCustomer";
import { useMaterialUIController, setToast } from "context";

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  accountId: number;
}

const DeleteDialog = ({ open, onClose, accountId }: DeleteDialogProps) => {
  const [controller, dispatch] = useMaterialUIController();
  const { mutate: deleteAccount, isPending } = useDeleteCustomerAccount();

  const handleDelete = () => {
    deleteAccount(
      { accountId },
      {
        onSuccess: () => {
          setToast(dispatch, {
            open: true,
            message: "Account deleted successfully",
            snackbarProps: {
              autoHideDuration: 3500,
            },
            alertProps: {
              severity: "success",
            },
          });
          onClose();
        },
        onError: () => {
          setToast(dispatch, {
            open: true,
            message: "Failed to delete an account",
            snackbarProps: {
              autoHideDuration: 3500,
            },
          });
          onClose();
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Account</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this account?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} variant="contained" color="error" loading={isPending}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
