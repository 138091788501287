type Types = any;

const listItemIcon: Types = {
  styleOverrides: {
    root: {
      minWidth: "32px",
    },
  },
};

export default listItemIcon;
