import { NavLink } from "react-router-dom";
import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import brandWhite from "assets/images/icon-vector.svg";
import colors from "assets/theme/base/colors";

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: "none",
  color: colors.black.main,
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const StyledLogo = styled("img")(() => ({
  display: "block",
  width: "24px",
  height: "24px",
}));

const Logo = () => {
  return (
    <Box role="presentation" sx={{ display: "flex", alignItems: "center", gap: 8 }}>
      <StyledNavLink to="/">
        <StyledLogo src={brandWhite} alt="LinenMaster" />
        <Typography variant="body1">LinenMaster</Typography>
      </StyledNavLink>
    </Box>
  );
};

export default Logo;
