import { useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { CustomerContext } from "context/customer";

const ListDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { isDestinationVisible } = useContext(CustomerContext);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        onClick={() => handleNavigate(`/customers/${params.id}`)}
        selected={location.pathname === `/customers/${params.id}`}
      >
        <ListItemText primary="Details" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleNavigate(`/customers/${params.id}/accounts`)}
        selected={location.pathname === `/customers/${params.id}/accounts`}
      >
        <ListItemText primary="Accounts" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleNavigate(`/customers/${params.id}/contracts`)}
        selected={location.pathname === `/customers/${params.id}/contracts`}
      >
        <ListItemText primary="Contracts" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleNavigate(`/customers/${params.id}/orders`)}
        selected={location.pathname === `/customers/${params.id}/orders`}
      >
        <ListItemText primary="Orders" />
      </ListItemButton>
      <ListItemButton
        onClick={() => handleNavigate(`/customers/${params.id}/soils`)}
        selected={location.pathname === `/customers/${params.id}/soils`}
      >
        <ListItemText primary="Soils" />
      </ListItemButton>
      {isDestinationVisible && (
        <ListItemButton
          onClick={() => handleNavigate(`/customers/${params.id}/destinations`)}
          selected={location.pathname === `/customers/${params.id}/destinations`}
        >
          <ListItemText primary="Destinations" />
        </ListItemButton>
      )}
    </List>
  );
};

export default ListDetails;
