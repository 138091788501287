import boxShadows from "assets/theme/base/boxShadows";
import colors from "assets/theme/base/colors";

type Types = any;

const appBar: Types = {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: boxShadows.sm,
      backgroundColor: colors.white.main,
    },
  },
};

export default appBar;
