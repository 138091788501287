import { Autocomplete } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { DropDownItemVM, DropDownVm } from "services/information_manager_api/generated";
import { useMemo } from "react";

interface DropdownCellProps {
  dropdownVm: DropDownVm;
  onChange: (newValue: DropDownItemVM | null) => void;
}

function DropdownCell({ dropdownVm, onChange }: DropdownCellProps): JSX.Element {
  const selectedItem = dropdownVm.items?.find((item) => item.id === dropdownVm.selectedId) || null;

  // Calculate minimum width based on longest option text
  const minWidth = useMemo(() => {
    const longestOption = dropdownVm.items?.reduce(
      (longest, current) =>
        (current.name?.length || 0) > (longest.name?.length || 0) ? current : longest,
      { name: "" }
    );
    // Approximate width based on character count (1ch ≈ 8px) plus padding
    return `${(longestOption?.name?.length || 0) * 8 + 50}px`;
  }, [dropdownVm.items]);

  return (
    <MDBox>
      <Autocomplete
        value={selectedItem}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        options={dropdownVm.items || []}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => <MDInput {...params} variant="standard" />}
        size="small"
        sx={{
          minWidth,
          "& .MuiAutocomplete-popper": {
            minWidth,
          },
        }}
      />
    </MDBox>
  );
}

export default DropdownCell;
